// @flow

import React, { useState, useEffect } from 'react'
import { get, omit, map } from 'lodash-es'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import MailMessageFull from '../../Mail/MailMessageFull'
import MailMessageShort from '../../Mail/MailMessageShort'
import { createEmailMessage } from '../../../core/api/api.request'
import { checkPending } from '../EmailPending'
import {
  convertUserToManagerSearchObj,
  getForwardMessage,
  getReplyMessage,
  isDwellerUser,
} from '../../../utils/utils'
import { modalError } from '../../Layout/Layout.actions'
import { newLineToBr } from '../../../utils/text'

const Message = props => {
  const {
    message,
    user,
    messages,
    selectedMessageId,
    requestId,
    buildingId,
    audience,
    uuid,
    selectedFiles,
    externalFiles,
    subject,
    isDeny,
    pending,
    directoryId,
    scope = 'request',
    object,
    flatId,
    outbound,
  } = props

  const { t } = useTranslation('Request')

  const dispatch = useDispatch()

  const [working, setWorking] = useState(false)
  const [isMass, setIsMass] = useState(false)

  useEffect(() => {
    if (props.pending) {
      checkPending([{ uuid, has_pending_message: pending }], () => {
        props.setPending(false)
        props.setLoading(true)
      })
    }
  }, [props.pending])

  const isDweller = isDwellerUser(user)

  const code = get(user, ['language_obj', 'code'])

  const getImgString = alt => {
    return `<p>[ ${alt} ]</p>`
  }

  const getQuote = text => {
    return `<p><br></p><blockquote>${text.replace(
      /<img.*?alt=['"](.*?)['"].*?>/g,
      getImgString('$1')
    )}</blockquote>`
  }

  const getReplyValues = (
    uuid,
    owner,
    toList,
    subject,
    created,
    sender_email
  ) => {
    let to = []

    if (user.owner === message.owner_obj.id && message.to_objs.length) {
      to = message.to_objs.map(someone =>
        convertUserToManagerSearchObj(someone)
      )
    } else {
      to = [convertUserToManagerSearchObj(message.owner_obj)]
    }

    return {
      to,
      cc: [],
      bcc: [],
      message: `${getReplyMessage(owner, created, t, sender_email)}${getQuote(
        newLineToBr(message.text)
      )}`,
      title: subject,
    }
  }

  const getReplyAllValues = (
    uuid,
    owner,
    toList,
    subject,
    created,
    sender_email
  ) => {
    const message = messages.find(m => m.uuid === uuid)

    let to = [message.owner_obj].concat(message.to_objs)

    to = to.filter(u => u.id !== user.owner)

    return {
      to: to.map(convertUserToManagerSearchObj),
      bcc: [],
      cc: message.cc_objs.map(convertUserToManagerSearchObj),
      message: `${getReplyMessage(owner, created, t, sender_email)}${getQuote(
        newLineToBr(message.text)
      )}`,
      title: subject,
    }
  }

  const getForwardValues = (
    uuid,
    owner,
    toList,
    subject,
    created,
    sender_email
  ) => {
    const message = messages.find(m => m.uuid === uuid)

    return {
      cc: [],
      bcc: [],
      to: [],
      message: getForwardMessage(
        owner,
        message.to_objs,
        message.cc_objs,
        subject,
        created,
        newLineToBr(message.text),
        t,
        sender_email
      ),
      files: message.files,
      title: subject,
    }
  }

  const isLastMessage = message.uuid === messages[messages.length - 1].uuid

  const handleClick = uuid => props.setSelectedMessageId(uuid)

  const handleMass = mass => {
    setIsMass(mass)
  }

  const handleSave = params => {
    setWorking(true)

    createEmailMessage(requestId, {
      ...omit(params, ['title', 'to', 'cc', 'bcc', 'files', 'text']),
      subject: params.title,
      owner: user.id || user.owner,
      audience,
      text: params.text.replace(/\n/g, ''),
      request_thread: uuid,
      to: params.to,
      cc: params.cc,
      bcc: params.bcc,
      files: params.files.concat(map(selectedFiles, 'id')),
    })
      .then(data => {
        if (
          props.setUuid &&
          props.setSubject &&
          data.results?.objects[0] &&
          uuid !== data.results.objects[0].request_thread
        ) {
          props.setUuid(data.results.objects[0].request_thread)
          props.setSubject(params.title)
        }

        props.clearSelectedFiles()
        props.setLoading(true)
        props.setPending(true)
      })
      .catch(error => {
        if (error.message.response.status === 400) {
          dispatch(modalError(error.message.response.data))
        }

        setWorking(false)
      })
      .finally(() => setWorking(false))
  }

  if (message.uuid === selectedMessageId || isLastMessage) {
    const fullMessageProps = {
      ...message,
      pending: pending,
      isToHided: true,
      onGetReplyValues: getReplyValues,
      onGetReplyAllValues: getReplyAllValues,
      onGetForwardValues: getForwardValues,
      showReplyForm: isLastMessage,
      onCollapse: () => props.setSelectedMessageId(null),
    }

    return (
      <MailMessageFull
        {...fullMessageProps}
        isMenuHidden
        isSystemIncluding
        isRequestThread
        withProviders
        hasSystemEmail
        showAddButton
        scope={scope}
        object={object}
        isDweller={isDweller}
        requestId={requestId}
        buildingId={buildingId}
        flatId={flatId}
        outbound={outbound}
        removeExternalFile={props.removeExternalFile}
        filesHeaderTitle={t('AttachedFiles')}
        working={working}
        createProps={{ ...fullMessageProps, files: [] }}
        isLastMessage={isLastMessage}
        externalFiles={externalFiles}
        setSelectedFiles={props.setSelectedFiles}
        changedValues={props.changedValues}
        threadTitle={subject}
        isMass={isMass}
        isDeny={isDeny}
        directoryId={directoryId}
        setChangedValues={props.setChangedValues}
        onDelete={props.onDelete}
        onResend={props.onResend}
        onConverToRequest={props.onConvert}
        onAttachToRequest={props.onAttach}
        onSave={handleSave}
        onToggleMass={handleMass}
      />
    )
  }

  return (
    <MailMessageShort
      {...message}
      languageCode={code}
      onClickMessage={handleClick}
    />
  )
}

export default Message
